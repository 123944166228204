<template>
  <div>
    <v-container fluid grid-list-xl>
      <v-layout row>
        <v-flex xs12 sm2>
          <moderation-aside />
        </v-flex>

        <v-flex xs12 sm10>
          <router-view />
        </v-flex>
      </v-layout>
    </v-container> 
  </div>
</template>

<script>
import ModerationAside from "./ModerationAside"

export default {
  components: {
    ModerationAside
  }
}
</script>
