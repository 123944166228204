<template>
  <div>
    <v-list class="py-0">
      <v-btn
        :to="{ name: 'users' }"
        :class="{ 'v-btn--active': subIsActive('/moderation/user_list') }"
        block
        flat
        large
        class="ma-0"
      >
        User list
      </v-btn>

      <v-btn
        :to="{ name: 'published_comments' }"
        :class="{ 'v-btn--active': subIsActive('/moderation/comment_list') }"
        block
        flat
        large
        class="ma-0"
      >
        Comment list
      </v-btn>

      <v-btn
        :to="{ name: 'posts' }"
        :class="{ 'v-btn--active': subIsActive('/moderation/post_list') }"
        block
        flat
        large
        class="ma-0"
      >
        Post list
      </v-btn>

      <v-btn
        :to="{ name: 'all_verification_videos' }"  
        :class="{ 'v-btn--active': subIsActive('/moderation/verification') }"
        block
        flat
        large
        class="ma-0"
      >
        Verification Videos
      </v-btn>

      <v-btn
        :to="{ name: 'monetization_posts' }"
        :class="{ 'v-btn--active': subIsActive('/moderation/monetization_posts') }"
        block
        flat
        large
        class="ma-0"
      >
        Monetization Post list
      </v-btn>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "ModerationAside",
  methods: {
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input]
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0
      })
    }
  }
}
</script>
